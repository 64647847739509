import gql from 'graphql-tag'

export const colors = {
    primary: {
        black: '#070512',
        white: '#FFFFFF'
    },
    secondary: {
        pink: '#FEECF8',
        blue: '#5A5DEE',
        gray1: '#636363'
    }
}

enum BasicRoutes {
    HOME = '/',
    BLOG = '/blog',
    CONTACTS = '/contacts'
}

enum HashParams {}

enum ExternalRoutes {
    CAREER = 'https://career.qusion.com/',
    VOS = 'https://vos.health/',
    REFOCUS = `https://www.qusion.com/en/blog/qusion-navazuje-strategicke-partnerstvi-s-futured`,
    // Social
    GITHUB = 'https://github.com/Qusion',
    DRIBBBLE = 'https://dribbble.com/qusion',
    FACEBOOK = 'https://www.facebook.com/QusionLAB',
    INSTAGRAM = 'https://www.instagram.com/qusion_com',
    LINKEDIN = 'https://www.linkedin.com/company/qusion',
    PRESS_KIT = 'https://drive.google.com/drive/folders/1qk0bfuq6aJhy0NzwIpUgrzsQt0oNq-br',
    // TODO:
    TERMS = '#',
    COOKIE = '#'
}

export type RoutesType = BasicRoutes | HashParams | ExternalRoutes

export const Routes = {
    ...BasicRoutes,
    ...HashParams,
    ...ExternalRoutes
}

export const categories = [
    'all',
    'design',
    'development',
    'events',
    'community'
]

export const transition = {
    main: `all ease-in 0.25s`
}

const customMediaQuery = (minWidth: number): string =>
    `@media (min-width: ${minWidth}px)`

export const media = {
    custom: customMediaQuery,
    xl: customMediaQuery(1170),
    desktop: customMediaQuery(960),
    tablet: customMediaQuery(854),
    phone: customMediaQuery(480)
}

export const cmsQueries = {
    /**
     * CMS queries:
     *
     * On server side we fetch and writeData
     * to Apollo cache with: client.cache.writeData
     *
     * See: server/render.tsx
     */
    POSTS: gql`
        query Posts {
            posts {
                id
                slug
                text
                title
                tags
                mainImg
                createdAt
                categories {
                    id
                    name
                }
                author {
                    name
                    profileImg
                }
            }
        }
    `
}
