/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import apolloLogger from 'apollo-link-logger'
import React from 'react'
import { hydrate } from 'react-dom'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { CookiesProvider } from 'react-cookie'
import smoothscroll from 'smoothscroll-polyfill'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider, withSSR } from 'react-i18next'
import {
    InMemoryCache,
    IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'

import App from './App'
import i18n from '../common/i18n'
import ScrollToTop from './components/ScrollToTop'

/**
 * Kick off the polyfills if it needed
 */
smoothscroll.polyfill()

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: []
        }
    }
})

/**
 * Cache are restored from window object
 * sent from the server
 */
const cache = new InMemoryCache({
    cacheRedirects: {
        Query: {}
    },
    fragmentMatcher: fragmentMatcher
}).restore(window.__APOLLO_STATE__)

/**
 * Catching errors
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (
        graphQLErrors &&
        graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
    ) {
        console.error(graphQLErrors)
        location.reload()
    }
    if (networkError) {
        console.error(networkError)
    }
})

const BACK_API_URL = process.env.RAZZLE_API_URL

const link = new HttpLink({
    uri: BACK_API_URL,
    credentials: 'include'
})

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, apolloLogger, link]),
    defaultOptions: {
        query: {
            errorPolicy: 'all'
        },
        mutate: {
            errorPolicy: 'all'
        },
        watchQuery: {
            errorPolicy: 'all'
        }
    },
    cache
})
const BaseApp: React.FC = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <CookiesProvider>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <HelmetProvider>
                            <ScrollToTop />
                            <App />
                        </HelmetProvider>
                    </BrowserRouter>
                </ApolloProvider>
            </CookiesProvider>
        </I18nextProvider>
    )
}

const ExtendedApp = withSSR()(BaseApp)

/**
 * Wait for all loadable components to be loaded before rendering.
 * Beacuse Loadable components loads all our scripts asynchronously.
 */
loadableReady(() => {
    const root = document.getElementById('root')
    hydrate(
        <ExtendedApp
            initialLanguage={window.initialLanguage}
            initialI18nStore={window.initialI18nStore}
        />,
        root
    )
})

if (module.hot) {
    module.hot.accept()
}
