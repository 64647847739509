import React from 'react'
import { Switch } from 'react-router-dom'
import loadable from '@loadable/component'

import './App.css'
import { routes } from './routes'
import AppRoute from './AppRoute'
const Nav = loadable(() => import('./components/Nav'))
const Cookie = loadable(() => import('./components/Cookie'))
const Footer = loadable(() => import('./components/Footer'))

const App = () => (
    <>
        <Nav />

        <Switch>
            {routes().map((route, index) => (
                <AppRoute
                    exact
                    path={route.path}
                    isPrivate={route.isPrivate}
                    component={route.component}
                    key={route.path.toString() + index}
                />
            ))}
        </Switch>

        {/* <Cookie /> */}
        <Footer />
    </>
)

export default App
