import { Routes } from '@variables'
import loadable from '@loadable/component'

const Home = loadable(() => import('./pages/home'))
const Blog = loadable(() => import('./pages/blog'))
const Post = loadable(() => import('./pages/post'))
const Contacts = loadable(() => import('./pages/contacts'))

export const routes = () => {
    return [
        {
            component: Contacts,
            isPrivate: false,
            path: [`${Routes.CONTACTS}`, `/:lang${Routes.BLOG}`]
        },
        {
            component: Post,
            isPrivate: false,
            path: [`${Routes.BLOG}/:slug`, `/:lang${Routes.BLOG}/:slug`]
        },
        {
            isPrivate: false,
            component: Blog,
            path: [Routes.BLOG, `/:lang${Routes.BLOG}`]
        },
        {
            isPrivate: false,
            component: Home,
            path: '*'
        }
    ]
}
