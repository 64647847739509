import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { Routes } from '@variables'
import { ComponentType } from 'react'

type Props = {
    path: TODO_ANY
    component: TODO_ANY
    isPrivate: boolean
}

const AppRoute: ComponentType<TODO_ANY> = ({
    component: Component,
    path,
    isPrivate,
    ...rest
}: Props) => {
    // const { isLoggedIn, isLoggingIn } = useContext(AuthContext)

    return (
        <Route
            path={path}
            render={(props) =>
                <Component {...props} />
                // isPrivate && !isLoggedIn && !isLoggingIn ? (
                //     <Redirect to={{ pathname: Routes.LOGIN }} />
                // ) : (
                //     <Component {...props} />
                // )
            }
            {...rest}
        />
    )
}

export default AppRoute
